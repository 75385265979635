
            @import 'src/styles/mixins.scss';
        

@import '@styles/sassVariables';
.menu-item {
    transition: all 350ms ease-in-out;
    &:hover{
        color: $antivirus-choice-blue;
    }
}
